<template>
  <div>
    <div class="block" style="margin-left: 200px">
      <span class="demonstration" style="margin-right: 10px">时间</span>
      <el-date-picker
          v-model="time"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          @change="changeTime">
      </el-date-picker>
      <span style="margin-left: 40px">{{txt1}}：<span style="font-weight: bolder;color: #f56c6c;font-size: 22px">{{num}}</span>次</span>
    </div>
    <div style="width: 100%;height: 600px;" ref="charts" class="charts">
    </div>
  </div>
</template>

<script>
import {timestampToTime} from '@/utils/utils'
import * as echarts from 'echarts';
export default {
  name: "UserCharts1",
  data() {
    var myDate = new Date();
    var year = myDate.getFullYear();    //获取完整的年份
    var month = (myDate.getMonth()+1)>9?myDate.getMonth()+1 : '0'+(myDate.getMonth()+1);       //获取当前月份(0-11,0代表1月)
    var day = (myDate.getDate())>9?myDate.getDate()+1 : '0'+(myDate.getDate());        //获取当前日(1-31)
    //var day2 =
    var date = year+'-'+month+'-'+day
    return {
      time: ["2021-03-04", "2021-04-13"],
      // option: {
      //   xAxis: {
      //     type: 'category',
      //     data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Su1n']
      //   },
      //   yAxis: {
      //     type: 'value'
      //   },
      //   series: [{
      //     data: [820, 932, 901, 934, 1290, 1330, 1320],
      //     type: 'line',
      //     smooth: true
      //   }]
      // },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      beginTime: '',
      endTime: '',
      myDate: date,
      num: 0,
	  id:0,
	  txt1:''
    }
  },
  created() {
    const end = new Date();
    const start = new Date();
    var end2 = end.getTime()
    var start2 = start.getTime() - 3600 * 1000 * 24 * 7
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    console.log(timestampToTime(start2/1000),timestampToTime(end2/1000))
    this.time = [timestampToTime(start2/1000),timestampToTime(end2/1000)]
    this.beginTime = timestampToTime(start2/1000)
    this.endTime = timestampToTime(end2/1000)
	this.id = this.$route.params.id
	console.log(this.id,'!!!!!!!!!id');
	if(this.$route.params.type == 0){
		this.txt1 = '上升次数'
	}else{
		this.txt1 = '下降次数'
	}
    //this.time =
  },
  mounted() {
    this.getCharts()

  },
  methods: {
    // 加载图表

    getCharts() {
		if(this.$route.params.type == 0){
			//获取数据
			this.$ajax('Czcs', 'post', {appUserId:this.$route.params.id,beginTime: this.beginTime, endTime: this.endTime}).then(res => {
			  console.log(res)
			  var rechargeAmount = []
			  var date = []
			  var num = 0
			  res.data.forEach((item, index) => {
			    console.log(item)
			    num += item.riseCount
			    rechargeAmount.push(item.riseCount)
			    date.push(item.gmtCreate)
			  })
			  this.num = num
			  //console.log(num, date, 'sss')
			  // 配置图表内容
			  var option = {
			    xAxis: {
			      type: 'category',
			      data: date
			    },
			    toolbox: {
			      feature: {
			        saveAsImage: {}
			      }
			    },
			    tooltip: {
			      trigger: 'axis',
			      axisPointer: {
			        type: 'cross',
			        label: {
			          backgroundColor: '#6a7985'
			        }
			      }
			    },
			    yAxis: {
			      type: 'value'
			    },
			    series: [{
			      name: '次数',
			      data: rechargeAmount,
			      type: 'line',
			      smooth: true
			    }]
			  }
			  var chartDom = this.$refs.charts;
			  var myChart = echarts.init(chartDom);
			  option && myChart.setOption(option);
			})
		}else{
			//获取数据
			this.$ajax('Czcs', 'post', {appUserId:this.$route.params.id,beginTime: this.beginTime, endTime: this.endTime}).then(res => {
			  console.log(res)
			  var rechargeAmount = []
			  var date = []
			  var num = 0
			  res.data.forEach((item, index) => {
			    console.log(item)
			    num += item.declineCount
			    rechargeAmount.push(item.declineCount)
			    date.push(item.gmtCreate)
			  })
			  this.num = num
			  //console.log(num, date, 'sss')
			  // 配置图表内容
			  var option = {
			    xAxis: {
			      type: 'category',
			      data: date
			    },
			    toolbox: {
			      feature: {
			        saveAsImage: {}
			      }
			    },
			    tooltip: {
			      trigger: 'axis',
			      axisPointer: {
			        type: 'cross',
			        label: {
			          backgroundColor: '#6a7985'
			        }
			      }
			    },
			    yAxis: {
			      type: 'value'
			    },
			    series: [{
			      name: '次数',
			      data: rechargeAmount,
			      type: 'line',
			      smooth: true
			    }]
			  }
			  var chartDom = this.$refs.charts;
			  var myChart = echarts.init(chartDom);
			  option && myChart.setOption(option);
			})
		}
     

    },
    changeTime(time) {
      console.log(time)
      if(time) {
        this.beginTime = time[0]
        this.endTime = time[1]
      } else {
        this.beginTime = ''
        this.endTime = ''
      }
      //this.getInfo()
	  this.getCharts()
    },
  },
}
</script>

<style scoped>

</style>

